.register {
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../../materials/register.png");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: right center;
}

.left-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
}

.header-text {
  color: #10bbf1;
  width: 50%;
  text-align: left;
  font-size: 3rem;
}

.form-register {
  width: 80%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.label-register {
  width: 60%;
  text-align: left;
  color: #10bbf1;
  font-weight: bold;
  font-size: larger;
  margin-top: 2.22%;
}

.input-register {
  width: 60%;
  height: 48px;
  margin-top: 5px;
  font-size: large;
  font-weight: bold;
  color: #b0b0b0;
  padding-left: 1.99%;
  background: #f7f7f7;
  border-radius: 10px;
  border: none;
}

.button-submit-register {
  margin-top: 100px;
  width: 20%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #00f7f7 -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

.text-link {
  text-align: left;
  line-height: 40%;
  color: #b0b0b0;
  margin-top: 5%;
}

@media only screen and (max-width: 720px) {
  .register {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    background-image: url("../../materials/register.png");
    background-size: contain;
  }

  .left-form {
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
  }

  .header-text {
    color: #10bbf1;
    width: 50%;
    text-align: left;
    font-size: 2rem;
  }

  .form-register {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .label-register {
    width: 60%;
    text-align: left;
    color: #10bbf1;
    font-weight: bold;
    font-size: larger;
    margin-top: 2.22%;
  }

  .input-register {
    width: 95%;
    height: 48px;
    margin-top: 5px;
    font-size: large;
    font-weight: bold;
    color: #b0b0b0;
    padding-left: 1.99%;
    background: #f7f7f7;
    border-radius: 10px;
    border: none;
  }

  .button-submit-register {
    margin-top: 30px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;

    background: linear-gradient(
        270deg,
        #00f7f7 -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }

  .text-link-register {
    text-align: center;
    line-height: 40%;
    color: #b0b0b0;
    margin-top: 10%;
    margin-left: 30%;
    margin-bottom: 10%;
  }
}
