.navbar {
  background-color: transparent;
  height: 10%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1%;
}

.account-profile {
  cursor: pointer;
  color: rgb(13, 13, 126);
}

.logo {
  background-color: transparent;
  height: auto;
  width: auto;
  text-align: left;
  cursor: pointer;
}

.menu {
  background-color: transparent;
  height: auto;
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bolder;
}

.menu p {
  cursor: pointer;
}

.menu p:hover {
  font-size: large;
  font-weight: bolder;
  color: #0b85ab;
}

.button-navbar {
  width: auto;
  min-width: 20%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0px 10px 0px 10px;
  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .navbar-transparent {
    height: 10%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 1%;
    color: white;
  }

  .navbar-transparent img {
    width: 30%;
  }

  .account-profile {
    cursor: pointer;
    color: rgb(0, 0, 3);
  }

  .logo {
    background-color: transparent;
    height: auto;
    width: auto;
    text-align: left;
    cursor: pointer;
  }

  .menu-transparent {
    background-color: transparent;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bolder;
  }

  .menu-transparent p {
    cursor: pointer;
    font-size: xx-small;
  }

  .menu-transparent p:hover {
    font-size: 10px;
    font-weight: bolder;
  }

  .button-navbar {
    width: auto;
    min-width: 10%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 0px 5px 0px 5px;
    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    font-size: smaller;
  }
}
