.article {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.container-article {
  width: 100%;
  height: 650px;
  border: none;
}

.slick-slide {
  height: 650px;
  box-sizing: border-box;
}

.slick-slide img {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 650px;
  z-index: -1;
}

.text-slider {
  position: absolute;
  color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  height: 650px;
  text-align: left;
  padding-left: 80px;
  background-color: rgb(0, 0, 0);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    93deg,
    rgba(0, 0, 0, 0.8071603641456583) 14%,
    rgba(255, 255, 255, 0) 100%
  );
}

.button-article {
  width: 20%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-top: 10%;

  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.button-article:hover {
  background: linear-gradient(
      270.45deg,
      #07d9d9 -11.66%,
      rgba(45, 138, 138, 0.552083) 47.02%,
      rgba(60, 96, 96, 0) 119.36%
    ),
    #0093e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.all-article {
  padding-top: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 720px) {
  .article {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .container-article {
    width: 100%;
    height: auto;
    border: none;
  }

  .slick-slide {
    height: 350px;
    box-sizing: border-box;
  }

  .slick-slide img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .text-slider {
    position: absolute;
    color: white;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: 650px;
    text-align: left;
    padding-left: 10%;
    background-color: rgb(0, 0, 0);
    background: rgb(0, 0, 0);
    background: linear-gradient(
      93deg,
      rgba(0, 0, 0, 0.8071603641456583) 14%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .text-slider h1 {
    width: 85%;
    color: white;
  }
  .text-slider p {
    width: 90%;
    color: white;
  }

  .button-article {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    margin-top: 10%;
    padding: 0px 15px 0px 15px;

    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
  }

  .button-article:hover {
    background: linear-gradient(
        270.45deg,
        #07d9d9 -11.66%,
        rgba(45, 138, 138, 0.552083) 47.02%,
        rgba(60, 96, 96, 0) 119.36%
      ),
      #0093e6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }

  .all-article {
    padding-top: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
