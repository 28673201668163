.question-container {
  font-weight: bold;
  font-size: 1.2rem;
  color: #117ea0;
  text-align: left;
  background-color: transparent;
  width: 60%;
  margin-top: 10%;
}

.question h1 {
  background-color: transparent;
  width: 90%;
}

.button-test {
  margin-top: 100px;
  width: 20%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
}

.grid-container {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 1% 1%;
  grid-template-areas:
    ". . . ."
    ". . . ."
    ". . . .";
  color: rgb(0, 0, 0);
}

.col {
  margin-top: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 10rem;
  height: 10rem;
  padding: 5%;
  background-color: #abe9e9;
  text-decoration: none;
}

.disabled {
  margin-top: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 10rem;
  height: 10rem;
  padding: 5%;
  background-color: #016d6d;
  text-decoration: none;
}

.col p {
  font-size: medium;
}

.active,
.col:hover {
  /* margin-left: 3%; */
  background-color: #0a6969;
  color: rgb(255, 255, 255);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="grid-container"] {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 1% 1%;
    grid-template-areas:
      ". ."
      ". ."
      ". .";
    color: rgb(0, 0, 0);
  }
  .col {
    margin-top: 2%;
    width: auto;
  }

  .col p {
    font-size: medium;
  }

  .col:hover {
    /* margin-left: 3%; */
    background-color: #0a6969;
    color: rgb(255, 255, 255);
  }

  .question-container {
    font-weight: bold;
    font-size: 0.9rem;
    color: #117ea0;
    text-align: center;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
  }

  .question h1 {
    text-align: center;
    margin-left: 20px;
  }

  .button-test {
    margin-top: 30%;
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20%;

    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
  }
}
