.read {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.container-read {
  width: 100%;
  height: 600px;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: rgb(231, 231, 231);
}

.container-read h1 {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  align-self: end;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: -50px;
  font-size: 2.8rem;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 23%,
    rgba(255, 255, 255, 0) 100%
  );
}

.container-blog {
  width: 50%;
  border: none;
  flex-direction: column;
  color: rgb(24, 24, 24);
  line-height: 150%;
  padding: 0 20% 0 20%;
  text-align: justify;
  margin-top: 100px;
}

.container-blog h1,
h2 {
  border: none;
  flex-direction: column;
  color: rgb(11, 5, 48);
  padding: 0 20% 0 20%;
  text-align: center;
}
@media only screen and (max-width: 720px) {
  .read {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    flex-direction: column;
  }

  .container-read {
    width: 100%;
    height: 600px;
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: rgb(231, 231, 231);
  }

  .container-read h1 {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    align-self: end;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: -50px;
    font-size: 1.4rem;

    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 23%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .container-read p {
    width: 100%;
    color: rgb(184, 184, 184);
    text-shadow: none;
  }
  .container-blog {
    width: 85%;
    border: none;
    flex-direction: column;
    color: rgb(24, 24, 24);
    line-height: 150%;
    padding: 0 20% 0 20%;
    text-align: justify;
    margin-top: 50px;
    text-shadow: none;
  }

  .container-blog h1,
  h2 {
    border: none;
    flex-direction: column;
    color: rgb(11, 5, 48);
    padding: 0 20% 0 20%;
    text-align: center;
  }
  .container-blog img {
    width: 100%;
    height: 60%;
  }
}
