.agreement {
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(252, 252, 252);
}

.container-agreement {
  font-weight: bold;
  font-size: 1.2rem;
  color: #117ea0;
  text-align: center;
  background-color: transparent;
  width: 60%;
  margin-top: 4%;
}

.form-agreement {
  margin-top: 5%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
}

.text-agreement {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: auto;
  margin-top: 20px;
}

.captcha {
  width: 80%;
  height: 200px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  margin-top: 10px;
  background-color: silver;
}
.input-text-agreement {
  margin-top: 20px;
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 98%;
  height: 50px;
  color: rgb(163, 163, 163);
  border: none;
  border-radius: 10px;
  text-align: center;
  font-size: large;
}

.change-method {
  background-color: red;
  width: 30%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: rgb(236, 236, 236);
  padding: 1%;
}

.upload-agreement {
  margin-top: 20px;
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 98%;
  height: 200px;
  color: rgb(163, 163, 163);
  border: none;
  border-radius: 10px;
  text-align: center;
  font-size: large;
  padding: 20px;
}

.button-nik {
  margin-top: 20px;
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #00b1f7 -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

.button-start {
  margin-top: 30px;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #354d6d -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

.button-result-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 720px) {
  .agreement {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(252, 252, 252);
  }

  .container-agreement {
    font-weight: bold;
    font-size: 1rem;
    color: #117ea0;
    text-align: center;
    background-color: transparent;
    width: 80%;
    margin-top: 4%;
  }
  .container-agreement p {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .form-agreement {
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 10%;
  }

  .text-agreement {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: auto;
    margin-top: 20px;
  }

  .input-text-agreement {
    margin-top: 20px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    height: 50px;
    color: rgb(163, 163, 163);
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: large;
  }

  .change-method {
    background-color: red;
    width: 30%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgb(236, 236, 236);
    padding: 1%;
  }

  .upload-agreement {
    margin-top: 20px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    height: 200px;
    color: rgb(163, 163, 163);
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: large;
    padding: 20px;
  }

  .button-nik {
    margin-top: 20px;
    width: 30%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;

    background: linear-gradient(
        270deg,
        #00b1f7 -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }

  .button-start {
    margin-top: 50px;
    margin-left: 0%;
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;

    background: linear-gradient(
        270deg,
        #354d6d -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }

  .button-result-container {
    display: flex;
    flex-direction: row;
  }
}
