.card-article-content {
  width: 80%;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 12px 80px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". .";

  margin-top: 2%;
}

.article-column {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-around;
  margin-top: 20px;
  border-radius: 20px;
  background-color: rgb(235, 235, 235);

  -webkit-box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
  box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
}
.article-column:hover {
  background: rgb(34, 193, 195);
  background: linear-gradient(
    38deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 115, 253, 1) 100%
  );
}

.article-column img {
  width: 60%;
  height: 100%;
  border-radius: 10px;
}

.article-column-title {
  width: 70%;
  max-height: 100%;
  border-radius: 5px;
  text-align: center;
  text-align: left;
  padding-left: 4%;
  border-radius: 10px;
  overflow: hidden;
}

.article-column h1 {
  color: #181717;
  text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.article-column h1:hover {
  color: #e6e6e6;
  text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.article-column p {
  width: 90%;
  height: 20%;
  border-radius: 5px;
  color: rgb(90, 90, 90);
}

@media only screen and (max-width: 720px) {
  .card-article-content {
    width: 90%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }

  .article-column {
    width: 90%;
    height: 100px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-around;
    margin-top: 10px;
    border-radius: 20px;
    background-color: rgb(235, 235, 235);

    -webkit-box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
    box-shadow: 1px -1px 73px -9px rgba(0, 0, 0, 0.08);
  }

  .card-article-content {
    width: 90%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }

  .article-column:hover {
    background: rgb(34, 193, 195);
    background: linear-gradient(
      38deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(45, 115, 253, 1) 100%
    );
    color: blanchedalmond;
  }

  .article-column img {
    width: 40%;
    height: 100%;
    border-radius: 10px;
  }

  .article-column-title {
    width: 70%;
    max-height: 100%;
    border-radius: 5px;
    text-align: center;
    text-align: left;
    padding-left: 4%;
    border-radius: 10px;
    overflow: hidden;
  }

  .article-column h1,
  p {
    color: #0c0c0c;
    text-shadow: -11px 15px 10px rgba(6, 0, 34, 0.22);
    cursor: pointer;
    font-size: 18px;
  }

  .article-column h1:hover {
    color: #ffffff;
    text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }

  .article-column p {
    width: 90%;
    height: 20%;
    border-radius: 5px;
    color: rgb(43, 43, 43);
    font-size: small;
  }
}
