.report {
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.lembar {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 3%;
  border-radius: 20px;
}

.container-report {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.report-table {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 3%;
  border-radius: 20px;
}

table {
  border: 2px black;
}

th {
  background-color: rgb(44, 6, 104);
  border: 2px black;
  color: white;
}
tr {
  border: 2px black;
}
