.login {
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header-text {
  color: #10bbf1;
  width: 50%;
  text-align: left;
  font-size: 3rem;
}

.container-login {
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  background-image: url("../../materials/login.png");
  background-repeat: no-repeat;
  background-position: right center;
}

.left-form-login {
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5%;
}

.form-login {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: transparent;
}

.label-login {
  width: 100%;
  text-align: left;
  color: #10bbf1;
  font-weight: bold;
  font-size: larger;
  margin-top: 5.22%;
}

.input-login {
  width: 100%;
  height: 48px;
  margin-top: 5px;
  font-size: large;
  font-weight: bold;
  color: #b0b0b0;
  padding-left: 1.99%;
  background: #f7f7f7;
  border-radius: 10px;
  border: none;
}

.button-login {
  margin-top: 100px;
  width: 20%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #00f7f7 -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

.text-link-login {
  text-align: left;
  line-height: 40%;
  color: #b0b0b0;
  margin-top: 5%;
}

@media only screen and (max-width: 720px) {
  .login {
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 2%;
    padding-bottom: 5%;
  }

  .header-text {
    color: #10bbf1;
    width: 50%;
    text-align: left;
    background-color: transparent;
    font-size: medium;
  }
  .header-text h1 {
    font-size: medium;
  }

  .container-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rebeccapurple;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10%;
    background-image: url("../../materials/login.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-color: transparent;
    margin-bottom: 100px;
  }

  .left-form-login {
    width: 80%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10%;
    background-color: transparent;
  }

  .form-login {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    justify-content: center;
  }

  .label-login {
    width: 100%;
    text-align: left;
    color: #05465a;
    font-weight: bold;
    font-size: larger;
    margin-top: 10.22%;
  }

  .input-login {
    width: 100%;
    height: 48px;
    margin-top: 5px;
    font-size: small;
    font-weight: bold;
    color: #535353;
    padding-left: 5.99%;
    background: #f7f7f7;
    border-radius: 10px;
    border: none;
  }

  .button-login {
    margin-top: 100px;
    width: 30%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;

    background: linear-gradient(
        270deg,
        #00f7f7 -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }
}
