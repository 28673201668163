.result {
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}

.question-container-result {
  font-weight: bold;
  font-size: 1.2rem;
  color: #117ea0;
  text-align: left;
  width: 50%;
  height: auto;
  padding-left: 5%;
}

.answer-form-result {
  margin-top: 5%;
  background-color: transparent;
  display: flex;
  justify-content: baseline;
}

.text-result {
  background-color: transparent;
  width: 60%;
  height: 100%;
  margin-left: 2%;
  color: black;
  text-align: justify;
  font-weight: normal;
  font-size: 16px;
}

.button-result {
  width: 30%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #00b1f7 -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.button-premium {
  margin-top: 100px;
  margin-left: 3%;
  width: 30%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #354d6d -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

.button-result-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 720px) {
  .result {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .question-container-result {
    font-weight: bold;
    font-size: 1.2rem;
    color: #117ea0;
    text-align: left;
    background-color: transparent;
    width: 90%;
    margin-top: 10%;
  }

  .answer-form-result {
    margin-top: 5%;
    background-color: transparent;
    display: flex;
    justify-content: baseline;
  }

  .text-result {
    background-color: transparent;
    width: 80%;
    margin-left: 2%;
    color: rgb(216, 216, 216);
  }

  .button-result {
    margin-top: 100px;
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 12px;

    background: linear-gradient(
        270deg,
        #00b1f7 -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }

  .button-premium {
    margin-top: 100px;
    margin-left: 3%;
    width: 40%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 12px;

    background: linear-gradient(
        270deg,
        #354d6d -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }

  .button-result-container {
    display: flex;
    flex-direction: row;
  }
}
