.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  background-color: transparent;
}

.App-header h4 {
  margin-bottom: 10%;
  color: #b6b3b3;
  font-size: large;
}

.App-link {
  color: #fcfcfc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-dnd {
  background-color: #fdfdfd;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.dnd-ranger {
  width: 8%;
  height: 55%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 1.5vmin);
  background-color: rebeccapurple;
  position: absolute;
  margin-right: 30%;
  background: rgb(0, 207, 232);
  background: linear-gradient(
    180deg,
    rgba(0, 207, 232, 1) 0%,
    rgba(6, 104, 154, 1) 100%
  );
}

.dnd-ranger p {
  width: 100%;
  font-size: medium;
}
.characters {
  list-style: none;
  padding-left: 0;
  width: 60%;
  margin-left: 23%;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
  color: #172b4d;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: 0.5em;
  margin-right: 0.5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.button-dnd {
  margin-top: 100px;
  margin-left: 3%;
  width: 20%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 1.2rem;
  padding: 0px 10px 0px 10px;

  background: linear-gradient(
      270deg,
      #354d6d -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #67bad5;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .container-dnd {
    background-color: #fdfdfd;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .dnd-ranger {
    width: 20%;
    height: 320px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(10px + 1.5vmin);
    background-color: rebeccapurple;
    position: static;
    margin-right: 00%;
    background: rgb(0, 207, 232);
    background: linear-gradient(
      180deg,
      rgba(0, 207, 232, 1) 0%,
      rgba(6, 104, 154, 1) 100%
    );
    background-color: red;
  }

  .dnd-ranger p {
    width: 100%;
    font-size: small;
    color: white;
  }
  .characters {
    list-style: none;
    padding-left: 0;
    width: 65%;
    margin-left: 5%;
  }

  .characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: 0.2em;
    padding: 0.5em 0.8em 0.5em 0.5em;
    margin-bottom: 1em;
  }

  .characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
    color: #172b4d;
  }

  .characters-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: 0.5em;
    margin-right: 0.5em;
  }

  .characters-thumb img {
    display: block;
    width: 100%;
    height: auto;
  }

  .button-dnd {
    margin-top: 100px;
    margin-left: 3%;
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bolder;
    font-size: 1.2rem;
    padding: 0px 10px 0px 10px;

    background: linear-gradient(
        270deg,
        #354d6d -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #67bad5;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    cursor: pointer;
  }
}
