.user-profile {
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: left center;
}

.jubmotron-profile {
  width: 100%;
  min-height: 650px;
  background-image: url("../../materials/bg-profile.svg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.container-jumbotron-profile {
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: row;
}
.jumbotron-user {
  width: 60%;
  text-align: left;
  padding-top: 1%;
}

#user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #ffffff;
  text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
}

#user-email {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
  margin-top: -3%;
}

.jumbotron-graphic {
  width: 40%;
  height: auto;
}
.username-profile {
  font-weight: bold;
  font-size: 2rem;
  color: #117ea0;
  text-align: left;
  line-height: 40%;
}
.username-profile p {
  font-size: 60%;
  color: black;
}

.container-profile {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.history-test-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 95%;
  min-height: 40vh;
  margin-top: 50px;
  margin-bottom: 50px;
}

.history-card {
  width: 19%;
  height: 256px;
  left: 1027px;
  top: 823px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background: linear-gradient(
      103.28deg,
      #1f69ab -20.65%,
      rgba(59, 134, 248, 0) 108.96%
    ),
    #47a5bd;
  mix-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-image: url("../../materials/teskarir.svg");
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-position-y: 20%;
  padding-bottom: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.history-card:hover {
  width: 20%;
  height: 290px;
}

.recomend-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  min-height: 600px;
  /* background-color: #0188c5; */
  padding-right: 20%;
  background-image: url("../../materials/rekomen-bg.png");
  background-position: left center;
  background-position-x: 100px;
  background-repeat: no-repeat;
  background-size: 30%;
}

.artikel-card {
  width: 15%;
  height: 300px;
  left: 1027px;
  top: 823px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  background: linear-gradient(
      103.28deg,
      #1f69ab -20.65%,
      rgba(59, 134, 248, 0) 108.96%
    ),
    #47a5bd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  mix-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 10%;
  padding-bottom: 10px;
  margin-left: 20px;
  cursor: pointer;
}

#color-overlay {
  top: 0;
  left: 0;
  background-color: black;
}

.artikel-card h2 {
  color: white;
}

.artikel-card:hover {
  width: 16%;
  height: 400px;
  color: white;
}

.submision {
  color: white;
  height: 1300px;
}

.userSubmision table {
  margin-top: auto;
  width: 20%;
  background-color: blue;
  border-top: 2px;
}

.submision-table {
  margin-top: 100px;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.userSubmision-sub th {
  margin-top: auto;
  background-color: #0188c5;
  border: 2px;
  padding: 5px;
  text-align: center;
}
.userSubmision-sub td {
  background-color: #ececec;
  padding-left: 10px;
  border: 2px;
  padding: 10px;
}

.submision h1 {
  color: white;
  font-weight: bolder;
  font-size: 2rem;
  width: 40%;
}

.submision h1 {
  color: #017ca3;
}

.submision p {
  width: 35%;
  font-weight: bold;
  color: #3f3f3f;
}

.process-submision {
  width: 40%;
  text-align: left;
}

.process-submision h2 {
  width: 90%;
  text-align: left;
  font-size: 1.2rem;
  color: white;
}

.process-item {
  background-color: royalblue;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
  border-radius: 20px;
  margin-top: 30px;

  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.process-item p {
  margin-left: 152px;
  margin-top: -10px;
  text-align: left;
  width: 70%;
  font-weight: normal;
  color: white;
}

.logout {
  margin-top: 10%;
  width: 400px;
  font-weight: normal;
  cursor: pointer;
}

.button-submision {
  margin-top: 100px;
  width: 40%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: bold;

  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

/* ================================ monbile phone=========================================== */

@media only screen and (max-width: 720px) {
  .user-profile {
    top: 0;
    width: 100%;
    display: flex;
    display: flex;
    justify-content: space-evenly;
  }

  .jubmotron-profile {
    width: 100%;
    min-height: 300px;
    background-image: url("../../materials/bg-profile.svg");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .container-jumbotron-profile {
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: row;
  }
  .jumbotron-user {
    width: 30%;
    text-align: left;
    margin-top: 20px;
  }

  #user-name {
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 59px;
    color: #ffffff;
    text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
  }

  #user-email {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-shadow: -11px 15px 10px rgba(0, 0, 0, 0.22);
  }

  .jumbotron-graphic {
    width: 10px;
    height: 40px;
    visibility: hidden;
  }
  .username-profile {
    font-weight: bold;
    font-size: 1.8rem;
    color: #117ea0;
    text-align: left;
    line-height: 40%;
  }
  .username-profile p {
    font-size: 60%;
    color: black;
  }

  .container-profile {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    padding-top: 0%;
  }

  .history-test-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
    min-height: 40vh;
  }

  .history-card {
    margin-top: 20px;
    width: 90%;
    height: 256px;
    left: 1027px;
    top: 823px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: linear-gradient(
        103.28deg,
        #1f69ab -20.65%,
        rgba(59, 134, 248, 0) 108.96%
      ),
      #47a5bd;
    mix-blend-mode: darken;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    background-image: url("../../materials/teskarir.svg");
    background-repeat: no-repeat;
    background-position-x: 85%;
    background-position-y: 20%;
    padding-bottom: 10px;
    margin-left: 20px;
    cursor: pointer;
  }

  .history-card:hover {
    width: 100%;
    height: 290px;
  }

  .username-profile {
    margin-top: 50px;
  }

  .recomend-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    min-height: 600px;
    padding-right: 005%;
    background-image: none;
    background-position: left center;
    background-position-x: 100px;
    background-repeat: no-repeat;
    background-size: 30%;
  }

  .artikel-card {
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    background: linear-gradient(
        103.28deg,
        #1f69ab -20.65%,
        rgba(59, 134, 248, 0) 108.96%
      ),
      #47a5bd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    mix-blend-mode: darken;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 10%;
    padding-bottom: 10px;
    margin-left: 20px;
    cursor: pointer;
  }

  #color-overlay {
    top: 0;
    left: 0;
    background-color: black;
  }

  .artikel-card h2 {
    color: white;
  }

  .artikel-card:hover {
    width: 90%;
    height: 400px;
  }

  .submision {
    padding-top: 10%;
    color: white;
  }

  .submision h1 {
    color: white;
    font-weight: bolder;
    font-size: 2rem;
    width: 80%;
  }

  .submision h1 {
    color: #017ca3;
  }

  .submision p {
    width: 80%;
    font-weight: bold;
    color: #017ca3;
  }

  .submision-table {
    width: 90%;
    overflow: scroll;
  }

  .userSubmision-sub table {
    width: 100%;
    margin-top: auto;
    border-top: 2px;
  }

  .userSubmision-sub th {
    background-color: #0188c5;
    border: 2px;
    padding: 5px;
    text-align: center;
    width: 0px;
  }
  .userSubmision-sub td {
    background-color: #ececec;
    padding-left: 10px;
    border: 2px;
  }

  .process-submision {
    width: 100%;
    text-align: left;
  }

  .process-submision h2 {
    width: 80%;
    text-align: left;
    font-size: 1.8rem;
    color: white;
  }

  .process-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 5px;
    border-radius: 20px;
    margin-top: 30px;
    width: 85%;
    margin-left: 4%;

    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .process-item p {
    margin-left: 40px;
    margin-top: 10px;
    text-align: left;
    width: 80%;
    font-weight: normal;
    color: white;
  }

  .button-submision {
    width: 60%;
    font-weight: bold;

    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
  }

  .logout {
    margin-top: 10%;
    width: 400px;
    font-weight: normal;
    cursor: pointer;
  }
}
