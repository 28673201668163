body {
  width: 100%;
  height: 900%;
}

.jumbotron {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container-jumbotron {
  width: 80%;
  height: 70vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jumbotron-text {
  width: 40%;
  height: 40%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.jumbotron-text span {
  line-height: 220%;
}
.jumbotron-text h1 {
  font-size: 35px;
}
.jumbotron-text h3 {
  font-size: 24px;
  color: #0c85ab;
}
.jumbotron-text p {
  font-size: 18px;
  color: black;
  width: 80%;
  margin-top: 5%;
}
.button-jumbotron {
  width: 35%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-top: 10%;

  background: linear-gradient(
      270.45deg,
      #03d2d2 -11.66%,
      rgba(68, 123, 123, 0.552083) 47.02%,
      rgba(133, 191, 191, 0) 119.36%
    ),
    #017ca3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.button-jumbotron:hover {
  background: linear-gradient(
      270.45deg,
      #07d9d9 -11.66%,
      rgba(45, 138, 138, 0.552083) 47.02%,
      rgba(60, 96, 96, 0) 119.36%
    ),
    #0093e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.image-jumbotron {
  width: 75%;
  height: 75%;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 150px;
}

.section-video {
  width: 100%;
  height: 100vh;
  background-image: url("../../materials/video-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin-top: 200px;
}

.section-video span {
  line-height: 90%;
}
.section-video h1 {
  font-size: 25px;
}
.section-video h3 {
  font-size: 24px;
  color: #0c85ab;
}
.section-video p {
  font-size: 14px;
  width: 40%;
}

iframe {
  margin-top: 20px;
  border-radius: 20px;
  height: 250px;
}

.section-company {
  width: 80%;
  height: 60vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}

.company-text {
  width: 40%;
  height: 20%;
  margin-bottom: 150px;
}

.company-text span {
  color: #10bbf1;
}
.company-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 90%;
  background-color: transparent;
}

.card {
  width: 20%;
  height: 100%;
  background-color: #ffffff;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.27);
  border-radius: 22px;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
}

.card-image {
  width: 100%;
}

.card h3 {
  font-size: 1rem;
  font-weight: bold;
}
.card p {
  color: #585858;
  text-shadow: none;
  text-align: justify;
  width: 90%;
  font-size: 12px;
  margin-bottom: 20px;
}

.button-card {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: small;
  font-weight: bold;

  background: linear-gradient(
      270deg,
      #19bcf0 -16.06%,
      rgba(133, 191, 191, 0) 125.18%
    ),
    #1bbcf0;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.section-result {
  width: 90%;
  height: 70vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}

.card-bg-text {
  text-shadow: none;
}

.section-result-text {
  width: 60%;
  color: #595959;
}
.section-result-text span {
  color: #10bbf1;
}

.result-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("../../materials/bg-profile-result.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-profile {
  width: 12%;
  height: 70%;
  background-color: #ffffff;
  padding: 5px;

  background: #ffffff;
  box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.27);
  border-radius: 22px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 2%;

}
.card-profile:hover {
  width: 12%;
  background-color: #ffffff;
  padding: 5px;

  background: #ffffff;
  box-shadow: 0px 7px 18px -3px rgba(255, 255, 255, 0.842);
  border-radius: 22px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 2%;
}

.card-image-profile {
  width: fit-content;
  height: auto;
  position: absolute;
  background-color: transparent;
  margin-left: 3%;
}

.card-profile h3 {
  width: 9%;
  font-size: 1.3rem;
  font-weight: bold;
  position: absolute;
  margin-top: 19%;
  margin-left: 1%;
}

/* bg sweeater */
.card-profile p {
  width: 100%;
  color: #d4d4d49a;
  background-color: transparent;
  width: 100%;
  font-size: 400%;
  font-weight: bold;
  line-height: 90%;
  transform: rotate(-3deg);
  display: absolute;
}

/* extension  tooltip*/
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.section-social-media {
  width: 90%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: url("../../materials//bg-social-media.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 110%;
  background-position-y: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.social-text h2 {
  justify-content: space-evenly;
}

.social-items {
  width: 700px;
  height: 180px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.items {
  width: 30%;
  height: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
}

/* ----------------------MEDIA QUERY FOR MOBILE PHONEEEE------------------- */

@media only screen and (max-width: 720px) {
  body {
    width: 100%;
    height: 900%;
  }

  .jumbotron {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .container-jumbotron {
    width: 100%;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5%;
  }

  .jumbotron-text {
    width: 80%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 0%;
  }
  .jumbotron-text span {
    line-height: 180%;
    background-color: #0093e6;
  }
  .jumbotron-text h1 {
    font-size: auto;
  }
  .jumbotron-text h3 {
    font-size: 14px;
  }
  .jumbotron-text p {
    font-size: 18px;
    color: black;
    width: 100%;
    text-shadow: none;
  }
  .button-jumbotron {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    background: linear-gradient(
        270.45deg,
        #03d2d2 -11.66%,
        rgba(68, 123, 123, 0.552083) 47.02%,
        rgba(133, 191, 191, 0) 119.36%
      ),
      #017ca3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
  }

  .button-jumbotron:hover {
    background: linear-gradient(
        270.45deg,
        #07d9d9 -11.66%,
        rgba(45, 138, 138, 0.552083) 47.02%,
        rgba(60, 96, 96, 0) 119.36%
      ),
      #0093e6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }

  .image-jumbotron {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }

  .image-jumbotron img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }

  .section-video {
    width: 100%;
    height: 150vh;
    background-image: url("../../materials/video-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15%;
    color: white;
  }

  .section-video-text {
    width: 80%;
    height:auto;
    margin-top: 5%;
  }
  .section-video span {
    line-height: 2%;
  }
  .section-video h1 {
    font-size: 25px;
  }
  .section-video h3 {
    font-size: 24px;
    color: #0c85ab;
  }
  .section-video p {
    font-size: 12px;
    width: 100%;
    color: white;
  }

  iframe {
    margin-top: 20px;
    border-radius: 20px;
    width: 80%;
    height: 20%;
  }

  .section-company {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .company-text {
    width: 90%;
    height: 20%;
    margin-bottom: 50px;
    text-shadow: none;
    font-size: auto;
  }

  .company-text h2 {
    text-align: center;
    font-size: 20px;
  }

  .company-text p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-shadow: none;
    margin-top: 60px;
  }

  .company-text span {
    color: #10bbf1;
  }

  .company-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 90%;
    background-color: transparent;
  }

  .card {
    width: 80%;
    height: 100%;
    background-color: #ffffff;
    padding: 5px;

    background: #ffffff;
    box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.27);
    border-radius: 22px;
    padding: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
  }

  .card-image {
    width: 100%;
  }

  .card h3 {
    width: 90%;
    font-size: auto;
    font-weight: bold;
    text-align: left;
  }
  .card p {
    color: #585858;
    margin-top: -10px;
    width: 90%;
  }

  .button-card {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    cursor: pointer;

    background: linear-gradient(
        270deg,
        #19bcf0 -16.06%,
        rgba(133, 191, 191, 0) 125.18%
      ),
      #1bbcf0;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
  }

  .section-result {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
  }

  .section-result-text {
    width: 85%;
    color: #595959;
  }
  .section-result-text span {
    color: #10bbf1;
  }

  .result-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("../../materials/bg-profile-result.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-profile {
    width: 50%;
    height: auto;
    background-color: #ffffff;
    padding: 5px;

    background: #ffffff;
    box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.27);
    border-radius: 22px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 20px;
  }

  .card-profile:hover {
    width: 50%;
    height: auto;
  }

  .card-image-profile {
    width: fit-content;
    height: auto;
    position: absolute;
    background-color: transparent;
    margin-left: 15%;
  }

  .card-profile h3 {
    width: 48%;
    font-size: 1.3rem;
    font-weight: bold;
    position: absolute;
    margin-top: 70%;
    margin-left: 1%;
  }

  /* bg sweeater */
  .card-profile p {
    width: 100%;
    color: #d4d4d49a;
    background-color: transparent;
    width: 100%;
    font-size: 400%;
    font-weight: bold;
    line-height: 90%;
    transform: rotate(-3deg);
    display: absolute;
    margin-top: 16px;
  }

  /* extension  tooltip*/
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .section-social-media {
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: rebeccapurple;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-items: center;
  }

  .social-text h2 {
    justify-content: space-between;
    font-size: 1rem;
  }

  .social-items {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: red;
  }

  .items {
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    border-radius: 10px;
    margin-left: 5px;
    padding: 10px;
  }
}
