.footer {
  width: 100%;
  background-color: #0188c5;
  margin-top: 10%;
  color: white;
  display: flex;
  padding: 20px 20px 20px 20px;
  justify-content: center;
  align-items: center;
}

.footer-container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-sociale {
  display: flex;
  width: 30%;
  justify-content: space-around;
  align-items: center;
}

.footer-sociale p {
  cursor: pointer;
  width: 90px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-spacing: 20px;
}

@media only screen and (max-width: 720px) {
  .footer {
    width: 100%;
    background-color: #0188c5;
    margin-top: 10%;
    color: white;
    display: flex;
    padding: 20px 0px 20px 0px;
    justify-content: center;
    align-items: center;
  }

  .footer-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-sociale {
    display: flex;
    width: 30%;
    justify-content: space-around;
    align-items: center;
    margin-right: 20px;
  }

  .footer-sociale p {
    cursor: pointer;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    word-spacing: 0px;
    font-size: small;
    margin-left: 5px;
  }
}
